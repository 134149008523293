var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pt-5",
      },
      [
        _c("h4", {}, [
          _vm._v(
            " " +
              _vm._s(
                _vm.saving
                  ? "Sending registration email..."
                  : "A registration email has been sent to the email address submitted during school admission."
              ) +
              " "
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }