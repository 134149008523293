<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid pt-5">
        <h4 class="">
            {{ saving ? 'Sending registration email...' : 'A registration email has been sent to the email address submitted during school admission.' }}
        </h4>
    </div>
</div>
</template>

<script>
import * as network from '../network';

export default {
    name: 'GuardianRegister',
    components: {
    },
    data() {
        return {
            saving: false,
        };
    },
    computed: {
        registrationGuid() {
            return this.$route.params.registrationGuid;
        },
    },
    watch: {
        registrationGuid: {
            handler() {
                if (!this.registrationGuid) return;
                this.sendRegistrationEmail();
            },
            immediate: true,
        },
    },
    methods: {
        sendRegistrationEmail() {
            const { registrationGuid } = this;
            if (this.saving || !registrationGuid) return;
            this.saving = true;

            network.guardians.sendRegistrationEmail({ url: { registrationGuid } }, (err, res) => {
                this.saving = false;
                return;
            });
        },
    },
};

</script>
